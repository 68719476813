<template>
  <div class="amap">
    <div class="main" id="amap">
      <el-amap-search-box
        class="search-box"
        :search-option="searchOption"
        :on-search-result="onSearchResult"
      ></el-amap-search-box>
      <el-amap
        ref="amap"
        vid="amap"
        :zoom="12"
        :center="marker.position"
        :events="events"
      >
        <el-amap-marker
          :position="marker.position"
          :visible="marker.visible"
        ></el-amap-marker>
      </el-amap>
    </div>
    <div class="result"></div>
  </div>
</template>

<script>
import axios from "axios";
export default {
  props: {
    types: {
      //搜索周边类型
      type: Array,
      default() {
        return [{ value: "地铁", key: "metro" }];
      }
    },
    location: {
      //当前位置
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      events: {
        click: e => {
          this.mapClick(e.lnglat);
        }
      },
      marker: {
        position: [104.065746, 30.657446],
        visible: false
      },
      searchOption: {
        citylimit: false
      },
      markers: [],
      address: ""
    };
  },
  computed: {
    amap() {
      return this.$refs.amap.$amap;
    }
  },
  watch: {
    location(arr, old) {
      if (arr.length && arr[0] && JSON.stringify(arr) !== JSON.stringify(old)) {
        this.marker.position = arr;
        this.marker.visible = true;
        this.searchNearBy(arr);
      }
    }
  },
  methods: {
    mapClick(e) {
      //点击地图
      const { lat, lng } = e;
      this.marker.position = [lng, lat];
      this.marker.visible = true;
      this.amap.panTo([lng, lat]);
      // 这里通过高德 SDK 完成。
      let geocoder = new AMap.Geocoder({
        radius: 1000,
        extensions: "all"
      });
      geocoder.getAddress([lng, lat], (status, result) => {
        let city = null;
        let address = null;
        let addressComponent = null;
        if (status === "complete" && result.info === "OK") {
          if (result && result.regeocode) {
            address = result.regeocode.formattedAddress;
            city = result.regeocode.addressComponent.citycode;
            addressComponent = result.regeocode.addressComponent;
          }
        }
        this.searchNearBy([lng, lat], city);
        this.$emit("getLL", {
          position: [lng, lat],
          address: address,
          addressComponent
        });
      });
    },
    async searchNearBy(pos, city) {
      //搜索周边
      let result = {};
      for (let key in this.types) {
        let res = await axios.get(`https://restapi.amap.com/v3/place/around`, {
          params: {
            key: "faca7f10327c4ada997ec60a588e6ffb",
            page: 1,
            offset: 20,
            city,
            location: pos.toString(),
            radius: 2000,
            types: this.types[key].value
          }
        });
        if (res.data.info == "OK") {
          result[this.types[key].key] = res.data.pois;
        }
      }
      this.$emit("placeSearch", result);
    },
    onSearchResult(pois) {
      //搜索
      if (pois.length) {
        this.mapClick(pois[0]);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.amap {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .main {
    flex: 1;
    position: relative;
    .search-box {
      position: absolute;
      top: 25px;
      left: 20px;
      width: 260px;
      height: 35px;
    }
  }
  .menu {
    margin-top: 5px;
    text-align: right;
  }
}
</style>
