<template>
  <el-dialog
    v-bind="$attrs"
    v-on="$listeners"
    class="f-dialog"
    title="创建楼盘"
    width="730px"
    top="50px"
    :close-on-click-modal="false"
    @close="cancel"
  >
    <el-form :model="formData" label-width="100px" ref="form">
      <div class="wrapper">
        <div class="left">
          <el-form-item
            label="项目名称："
            prop="estateName"
            :rules="[rules.required()]"
          >
            <el-input
              placeholder="请输入"
              v-model="formData.estateName"
            ></el-input>
          </el-form-item>
          <el-form-item label="城市商圈：" :rules="[rules.required()]">
            <el-form-item
              class="mr10 fl"
              prop="companyCode"
              :rules="[rules.required()]"
            >
              <el-cascader
                style="width:188px;"
                v-model="formData.companyCode"
                :options="cityList"
                :props="{
                  label: 'companyName',
                  value: 'companyCode',
                  emitPath: false
                }"
                placeholder="请选择城市"
                @change="cityChange"
              ></el-cascader>
            </el-form-item>
            <el-form-item class="fl" prop="areaId" :rules="[rules.required()]">
              <el-cascader
                style="width:188px;"
                v-model="formData.areaId"
                v-if="showAreaCascader"
                :options="areaList"
                separator=" - "
                :props="{
                  label: 'name',
                  value: 'id',
                  emitPath: false,
                  children: 'child'
                }"
                placeholder="请选择商圈"
              ></el-cascader>
            </el-form-item>
          </el-form-item>
          <el-form-item
            label="物业类型："
            prop="propertyTypesList"
            :rules="[rules.required()]"
            class="property-item"
          >
            <el-checkbox-group
              v-model="formData.propertyTypesList"
              @change="getSystemCharacteris"
              class="property"
            >
              <el-checkbox
                v-for="(value, key) in propertyTypesList"
                :label="Number(key)"
                :key="key"
                >{{ value }}</el-checkbox
              >
            </el-checkbox-group>
          </el-form-item>
          <el-form-item
            label="销售状态："
            prop="saleStatus"
            :rules="[rules.required()]"
            size="small"
          >
            <el-radio-group v-model="formData.saleStatus" class="sale-status">
              <el-radio-button
                v-for="(value, key) in saleStatus"
                :label="key"
                :key="Number(key)"
              >
                {{ value }}</el-radio-button
              >
            </el-radio-group>
          </el-form-item>

          <el-form-item label="主推面积：" :rules="[rules.required()]">
            <div class="line-box">
              <el-form-item
                class="fl"
                prop="houseAreaFrom"
                :rules="[rules.required(), rules.int]"
              >
                <el-input
                  class="fl"
                  style="width:160px;"
                  v-model.number="formData.houseAreaFrom"
                ></el-input>
              </el-form-item>
              <span class="pw10 fl">-</span>
              <el-form-item
                class="fl"
                prop="houseAreaTo"
                :rules="[rules.required(), rules.int]"
              >
                <el-input
                  style="width:201px;"
                  v-model.number="formData.houseAreaTo"
                >
                  <span slot="append">平米</span>
                </el-input>
              </el-form-item>
            </div>
          </el-form-item>
        </div>
        <div class="right">
          <el-form-item
            prop="sitePlan"
            :rules="[rules.required()]"
            label-width="0"
          >
            <f-upload
              title="缩略图"
              tips="楼盘列表缩略图，推荐尺寸300px * 400px"
              required
              :type="2"
              :default-file-list="[{ url: formData.sitePlan }]"
              @on-success="fileList => uploaderChange(fileList, 'sitePlan')"
            ></f-upload>
          </el-form-item>
        </div>
      </div>
      <el-form-item label="装修情况：">
        <el-input
          v-model="formData.decorationStandard"
          placeholder="装修情况"
        ></el-input>
      </el-form-item>
      <el-form-item
        label="项目位置："
        prop="estateAddress"
        :rules="[rules.required()]"
      >
        <div class="location-box">
          <el-input
            v-model="formData.estateAddress"
            placeholder="街道、门牌地址"
          ></el-input>
          <div class="btn-location" ref="estate" @click="showMap('estate')">
            <i class="el-icon-map-location"></i>
            <span>{{ formData.estateLL || "经纬度" }}</span>
          </div>
        </div>
      </el-form-item>
      <el-form-item label="售楼部位置：" prop="officeAddress">
        <div class="location-box">
          <el-input
            v-model="formData.officeAddress"
            placeholder="售楼部地址"
          ></el-input>
          <div class="btn-location" ref="office" @click="showMap('office')">
            <i class="el-icon-map-location"></i>
            <span>{{ formData.officeLL || "经纬度" }}</span>
          </div>
        </div>
      </el-form-item>
      <el-popover
        placement="bottom-end"
        width="542"
        trigger="click"
        v-if="showMapPopover"
        ref="pop"
        :reference="reference"
        @hide="showMapPopover = false"
      >
        <p class="f-form-tips mt10">请在地图上准确标注当前项目的位置坐标</p>
        <div class="map-wrap mt10">
          <a-map @getLL="getLL"></a-map>
        </div>
      </el-popover>
      <el-form-item label="项目特点：">
        <div class="f-form-block">
          <el-checkbox-group v-model="formData.characteristicsSystemList">
            <el-checkbox
              v-for="(item, index) in characteristicsSystemList"
              :key="index"
              :label="item"
              style="width:120px;"
              :disabled="
                isDisableCharacteristic &&
                  !formData.characteristicsSystemList.includes(item)
              "
            ></el-checkbox>
          </el-checkbox-group>
          <el-checkbox-group v-model="formData.characteristicsList">
            <el-checkbox
              v-for="(item, index) in characteristicsList"
              :key="index"
              :label="item"
              style="width:120px;"
              :disabled="
                isDisableCharacteristic &&
                  !formData.characteristicsList.includes(item)
              "
            ></el-checkbox>
          </el-checkbox-group>
          <el-input
            v-model="diyTag"
            maxlength="5"
            class="w180"
            placeholder="自定义"
            size="small"
            :disabled="isDisableCharacteristic"
          >
            <el-button slot="append" type="blue-text" @click="addTag"
              >确定</el-button
            >
          </el-input>
          <p class="f-form-tips mt10">
            最多添加4个标签，自定义标签不超过5个汉字
          </p>
        </div>
      </el-form-item>
      <el-form-item
        label="状态："
        prop="shelvesStatus"
        :rules="[rules.required()]"
      >
        <el-radio-group v-model="formData.shelvesStatus">
          <el-radio-button :label="1">上架</el-radio-button>
          <el-radio-button :label="2">下架</el-radio-button>
        </el-radio-group>
      </el-form-item>
      <el-form-item
        v-if="formData.shelvesStatus == 1"
        label=""
        prop="onLineChannel"
        :rules="[rules.required('至少选择一个')]"
        class="choose-channel"
      >
        <div class="f-form-block">
          <div class="tip">上架平台 <span>（至少上架1个平台）</span></div>
          <el-checkbox-group v-model="formData.onLineChannel">
            <el-checkbox
              :label="item.id"
              v-for="(item, index) in channelList"
              :key="index"
              style="width:130px;"
              :class="{
                'special-disabled':
                  distributionFlag && platformId == item.channelId
              }"
              :disabled="
                item.disabled ||
                  (distributionFlag && platformId == item.channelId)
              "
              :checked="distributionFlag && platformId == item.channelId"
              >{{ item.letTitle }}</el-checkbox
            >
          </el-checkbox-group>
        </div>
      </el-form-item>
    </el-form>
    <div slot="footer">
      <el-button @click="cancel">取 消</el-button>
      <el-button type="primary" @click="submit">确 定</el-button>
    </div>
  </el-dialog>
</template>

<script>
import rules from "@/utils/rules";
import aMap from "@/components/amap";
import qs from "querystring";
import {
  getEstatePage,
  getSystemCharacteris,
  createEstate,
  editVisiable
} from "@/api/estate";
import { getEnterpriseChannelStatus } from "@/api/distribution";
import { enumeration } from "@/config";
import FUpload from "@/components/upload";
import editEstateMixin from "@/views/estate/_mixins/editEstateMixin";

const formData = {
  //表单数据
  companyCode: null, //城市站点id 前端用
  areaId: null, //所属商圈 ,
  characteristicsList: [], //楼盘特点 ,
  characteristicsSystemList: [], //楼盘特点(系统预设) ,
  decorationStandard: "", //装修标准 ,
  estateAddress: "", //项目详细地址 ,
  estateCoverImage: "", //首页分享模板图 ,
  estateLogo: "", //项目标识
  estateLatitude: "", //项目地址纬度 ,
  estateLongitude: "", //项目地址经度 ,
  estateLL: "", //【前端用】经纬度
  officeAddress: "", //售楼部地址
  officeLatitude: "", //售楼部地址纬度 ,
  officeLongitude: "", //售楼部经度 ,
  officeLL: "", //【前端用】售楼部经纬度
  estateName: "", //项目名
  houseAreaFrom: "", //面积段
  houseAreaTo: "",
  propertyTypesList: [], //物业类型:1--普通住宅，2--公寓，3--别墅，4--写字楼，5--商铺 ,
  saleStatus: "", //销售状态:1.待售 2.即将开盘 3.在售 4.下期待开 5.已售罄 ,
  sitePlan: "", //项目缩略图 ,
  wechatQrcodePath: "", //购房群推广图
  shelvesStatus: 1, // 上架状态
  onLineChannel: [] // 上架平台
};

export default {
  mixins: [editEstateMixin],
  components: {
    aMap,
    FUpload
  },
  data() {
    return {
      rules,
      propertyTypesList: enumeration.propertyTypesList, //物业类型
      saleStatus: enumeration.saleStatus, //销售状态
      characteristicsSystemList: [], //楼盘特色标签
      characteristicsList: [], //自定义楼盘特色标签
      formData: JSON.parse(JSON.stringify(formData)),
      diyTag: "", //自定义标签输入框值
      distributionFlag: false, // 是否为分销平台
      popoverName: "", // 触发 Popover 显示的标签name
      reference: null, // 触发 Popover 显示的 HTML 元素
      showMapPopover: false // 控制显示地图弹窗
    };
  },
  computed: {
    platformId() {
      return this.$store.state.platformInfo.platformId;
    }
  },
  created() {
    // 判断是否为分销平台
    this.distributionFlag = this.$route.path.includes("distribution");
    this.getEnterpriseChannelStatus();
  },
  methods: {
    showMap(name) {
      if (this.showMapPopover) return;
      this.popoverName = name;
      this.reference = this.$refs[name]; // 触发 Popover 显示的 HTML 元素
      this.showMapPopover = true; // 打开地图弹窗
      this.$nextTick(() => {
        this.$refs.pop.doShow();
      });
    },
    async uploaderChange(fileList, type) {
      //上传图片
      this.formData[type] = fileList[0].url;
    },
    getLL({ address, position, addressComponent }) {
      let { province, city, district } = addressComponent;
      if (this.popoverName == "estate") {
        // 项目地址，替换省市区
        this.formData.estateAddress = address.replace(
          province + city + district,
          ""
        );
      }
      if (this.popoverName == "office") {
        // 售楼部地址，替换省市
        this.formData.officeAddress = address.replace(province + city, "");
      }
      //地图组件回调
      this.formData[`${this.popoverName}LL`] = position.join("，");
      this.formData[`${this.popoverName}Longitude`] = position[0];
      this.formData[`${this.popoverName}Latitude`] = position[1];
      this.showMapPopover = false; // 关闭地图弹窗
    },
    cancel() {
      this.characteristicsList = [];
      this.$refs.form.resetFields();
      this.formData.estateLL = "";
      this.formData.officeLL = "";
      this.$emit("update:visible", false);
    },
    submit() {
      //提交表单
      this.$refs.form.validate(async valid => {
        if (valid) {
          const { estateLatitude } = this.formData;
          if (!estateLatitude) {
            this.$showError("请选择项目位置经纬度");
            return;
          }
          const res = await createEstate({
            baseInfo: {
              ...this.formData,
              isDistribution: this.$route.path.includes("distribution") // 分销平台
            }
          });
          if (res) {
            this.$showSuccess("提交成功");
            this.formData = this.$deepClone(formData);
            this.cancel();
            this.$emit("refresh");
          }
        } else {
          // 验证不通过时，焦点跳转至第一个校验不通过处
          this.$nextTick(() => {
            let isError = document.getElementsByClassName("is-error");
            isError[0].querySelector("input").focus();
          });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
::v-deep {
  .special-disabled {
    .el-checkbox__input.is-disabled + span.el-checkbox__label {
      color: #333;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner {
      background-color: $primary;
      color: #fff;
      border-color: $primary;
    }
    .el-checkbox__input.is-disabled.is-checked .el-checkbox__inner::after {
      border-color: #fff;
    }
  }
}
::v-deep .el-form-item__content {
  line-height: 30px;
  .el-checkbox__label {
    color: #333;
  }
}
::v-deep .el-dialog__body {
  height: calc(100vh - 220px);
  overflow-y: auto;
}
::v-deep {
  .el-form-item {
    margin-bottom: 20px;
    .el-form-item {
      margin-bottom: 0;
    }
  }
  .el-input__inner {
    height: 36px;
    line-height: 36px;
  }
  .el-form-item__label {
    line-height: 36px;
  }
  .el-input__icon {
    height: 36px;
    line-height: 36px;
  }
}
.f-form-block {
  .tip {
    color: #333;
    margin-bottom: 5px;
    span {
      color: #aaa;
    }
  }
}
.f-dialog {
  .img-uploder-wrap {
    display: flex;
  }
}

.map-wrap {
  width: 510px;
  height: 250px;
}
.wrapper {
  display: flex;
  .left {
    flex: 1;
  }
  .right {
    width: 175px;
    height: 242px;
    margin-left: 25px;
    background: rgba(242, 242, 242, 1);
  }
}
.property-item {
  margin: -13px 0 12px 0;
}
.property {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 36px;
  .el-checkbox {
    margin-right: 0;
  }
}
.sale-status {
  ::v-deep .el-radio-button--small .el-radio-button__inner {
    padding: 10px 20px;
  }
}
.location-box {
  display: flex;
  align-items: center;
  ::v-deep {
    .el-input {
      flex: 1;
    }
  }
  .btn-location {
    width: 210px;
    line-height: 34px;
    border: 1px solid #dcdfe6;
    background-color: #f2f2f2;
    padding: 0 10px;
    margin-left: 10px;
    border-radius: 4px;
    cursor: pointer;
    i {
      margin-right: 10px;
      font-size: 18px;
    }
    &:hover {
      opacity: 0.75;
    }
  }
}
::v-deep {
  .picture-uploader-type2 .uploader .text {
    background-color: #ecf0fd;
    border: 1px dashed #3470fc;
    border-radius: 5px;
    &.mask {
      background-color: transparent;
      border: none;
    }
  }
  .picture-uploader-type2 .uploader img {
    border-radius: 5px;
  }
}
</style>
