<template>
  <el-cascader
    ref="areaCascader"
    :props="props"
    :options="cityList"
    clearable
    placeholder="城市-区域-商圈"
    @visible-change="cascaderVisibleChange"
    @expand-change="cascaderExpandChange"
    @change="areaSelect"
  ></el-cascader>
</template>

<script>
import { getSiteList, getAreaListBySite } from "@/api/common";
import { setInterval, clearInterval, setTimeout, clearTimeout } from "timers";
export default {
  data() {
    return {
      queryData: {
        companyCode: "", // 城市
        regionId: "", // 区域
        areaId: "" // 商圈
      },
      cityList: [],
      props: {
        label: "companyName",
        value: "companyCode",
        children: "child",
        lazy: true,
        checkStrictly: true,
        lazyLoad: this.getAreaList
      }
    };
  },
  created() {
    this.getSiteList();
  },
  methods: {
    cascaderVisibleChange(bool) {
      if (bool) {
        // 级联选择器显示时，为label绑定事件
        this.bindEvent(500);
      } else {
        // 级联选择器隐藏时，清除定时器
        clearTimeout(this.cascaderTimer);
      }
    },
    cascaderExpandChange() {
      // 当展开节点发生变化时
      clearTimeout(this.cascaderTimer);
      this.bindEvent(1000);
    },
    bindEvent(time) {
      // 点击radio，触发相临label点击事件
      this.$nextTick(() => {
        this.cascaderTimer = setTimeout(() => {
          let labels = document.querySelectorAll(
            ".el-cascader-node .el-cascader-node__label"
          );
          let radios = document.querySelectorAll(
            ".el-cascader-node .el-radio__inner"
          );
          if (radios && radios.length) {
            radios.forEach((item, index) => {
              item.addEventListener("click", () => {
                // 触发相临label点击事件
                labels[index].click();
              });
            });
          }
        }, time);
      });
    },
    areaSelect(arr) {
      // 选择 城市-区域-商圈
      this.queryData.companyCode = arr[0];
      this.queryData.regionId = arr[1] || null;
      this.queryData.areaId = arr[2] || null;
      if (this.queryData.areaId) {
        // 点击最后一级时，关闭选择器
        this.$refs.areaCascader.dropDownVisible = false;
        clearTimeout(this.cascaderTimer);
      }
      this.$emit("setQueryData", this.queryData);
    },
    async getSiteList() {
      // 获取城市站点列表
      const res = await getSiteList({ type: 1 });
      if (res) {
        this.cityList = res;
      }
    },
    async getAreaList(node, resolve) {
      // 根据城市，获取区域和商圈
      const { level } = node;
      if (node.data) {
        let childProjects = [];
        if (level < 2) {
          // 第2级数据从接口获取
          const res = await getAreaListBySite({
            companyCode: node.value,
            showLoading: false
          });
          if (res) {
            if (res instanceof Array) {
              childProjects = res;
            }
          }
        } else {
          // 3级数据从2级数据的子元素中获取
          childProjects = node.data.children || [];
        }
        const nodes = childProjects.map(item => {
          return {
            companyName: item.name,
            companyCode: item.id,
            leaf: !(item.child && item.child.length), // 根据child判断是否有子节点
            children: item.child
          };
        });
        resolve(nodes);
      }
    }
  }
};
</script>

<style scoped lang="scss"></style>
