var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-dialog', _vm._g(_vm._b({
    staticClass: "f-dialog",
    attrs: {
      "title": "创建楼盘",
      "width": "730px",
      "top": "50px",
      "close-on-click-modal": false
    },
    on: {
      "close": _vm.cancel
    }
  }, 'el-dialog', _vm.$attrs, false), _vm.$listeners), [_c('el-form', {
    ref: "form",
    attrs: {
      "model": _vm.formData,
      "label-width": "100px"
    }
  }, [_c('div', {
    staticClass: "wrapper"
  }, [_c('div', {
    staticClass: "left"
  }, [_c('el-form-item', {
    attrs: {
      "label": "项目名称：",
      "prop": "estateName",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "请输入"
    },
    model: {
      value: _vm.formData.estateName,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateName", $$v);
      },
      expression: "formData.estateName"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "城市商圈：",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-form-item', {
    staticClass: "mr10 fl",
    attrs: {
      "prop": "companyCode",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-cascader', {
    staticStyle: {
      "width": "188px"
    },
    attrs: {
      "options": _vm.cityList,
      "props": {
        label: 'companyName',
        value: 'companyCode',
        emitPath: false
      },
      "placeholder": "请选择城市"
    },
    on: {
      "change": _vm.cityChange
    },
    model: {
      value: _vm.formData.companyCode,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "companyCode", $$v);
      },
      expression: "formData.companyCode"
    }
  })], 1), _c('el-form-item', {
    staticClass: "fl",
    attrs: {
      "prop": "areaId",
      "rules": [_vm.rules.required()]
    }
  }, [_vm.showAreaCascader ? _c('el-cascader', {
    staticStyle: {
      "width": "188px"
    },
    attrs: {
      "options": _vm.areaList,
      "separator": " - ",
      "props": {
        label: 'name',
        value: 'id',
        emitPath: false,
        children: 'child'
      },
      "placeholder": "请选择商圈"
    },
    model: {
      value: _vm.formData.areaId,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "areaId", $$v);
      },
      expression: "formData.areaId"
    }
  }) : _vm._e()], 1)], 1), _c('el-form-item', {
    staticClass: "property-item",
    attrs: {
      "label": "物业类型：",
      "prop": "propertyTypesList",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-checkbox-group', {
    staticClass: "property",
    on: {
      "change": _vm.getSystemCharacteris
    },
    model: {
      value: _vm.formData.propertyTypesList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "propertyTypesList", $$v);
      },
      expression: "formData.propertyTypesList"
    }
  }, _vm._l(_vm.propertyTypesList, function (value, key) {
    return _c('el-checkbox', {
      key: key,
      attrs: {
        "label": Number(key)
      }
    }, [_vm._v(_vm._s(value))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "销售状态：",
      "prop": "saleStatus",
      "rules": [_vm.rules.required()],
      "size": "small"
    }
  }, [_c('el-radio-group', {
    staticClass: "sale-status",
    model: {
      value: _vm.formData.saleStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "saleStatus", $$v);
      },
      expression: "formData.saleStatus"
    }
  }, _vm._l(_vm.saleStatus, function (value, key) {
    return _c('el-radio-button', {
      key: Number(key),
      attrs: {
        "label": key
      }
    }, [_vm._v(" " + _vm._s(value))]);
  }), 1)], 1), _c('el-form-item', {
    attrs: {
      "label": "主推面积：",
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "line-box"
  }, [_c('el-form-item', {
    staticClass: "fl",
    attrs: {
      "prop": "houseAreaFrom",
      "rules": [_vm.rules.required(), _vm.rules.int]
    }
  }, [_c('el-input', {
    staticClass: "fl",
    staticStyle: {
      "width": "160px"
    },
    model: {
      value: _vm.formData.houseAreaFrom,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseAreaFrom", _vm._n($$v));
      },
      expression: "formData.houseAreaFrom"
    }
  })], 1), _c('span', {
    staticClass: "pw10 fl"
  }, [_vm._v("-")]), _c('el-form-item', {
    staticClass: "fl",
    attrs: {
      "prop": "houseAreaTo",
      "rules": [_vm.rules.required(), _vm.rules.int]
    }
  }, [_c('el-input', {
    staticStyle: {
      "width": "201px"
    },
    model: {
      value: _vm.formData.houseAreaTo,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "houseAreaTo", _vm._n($$v));
      },
      expression: "formData.houseAreaTo"
    }
  }, [_c('span', {
    attrs: {
      "slot": "append"
    },
    slot: "append"
  }, [_vm._v("平米")])])], 1)], 1)])], 1), _c('div', {
    staticClass: "right"
  }, [_c('el-form-item', {
    attrs: {
      "prop": "sitePlan",
      "rules": [_vm.rules.required()],
      "label-width": "0"
    }
  }, [_c('f-upload', {
    attrs: {
      "title": "缩略图",
      "tips": "楼盘列表缩略图，推荐尺寸300px * 400px",
      "required": "",
      "type": 2,
      "default-file-list": [{
        url: _vm.formData.sitePlan
      }]
    },
    on: {
      "on-success": function onSuccess(fileList) {
        return _vm.uploaderChange(fileList, 'sitePlan');
      }
    }
  })], 1)], 1)]), _c('el-form-item', {
    attrs: {
      "label": "装修情况："
    }
  }, [_c('el-input', {
    attrs: {
      "placeholder": "装修情况"
    },
    model: {
      value: _vm.formData.decorationStandard,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "decorationStandard", $$v);
      },
      expression: "formData.decorationStandard"
    }
  })], 1), _c('el-form-item', {
    attrs: {
      "label": "项目位置：",
      "prop": "estateAddress",
      "rules": [_vm.rules.required()]
    }
  }, [_c('div', {
    staticClass: "location-box"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "街道、门牌地址"
    },
    model: {
      value: _vm.formData.estateAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "estateAddress", $$v);
      },
      expression: "formData.estateAddress"
    }
  }), _c('div', {
    ref: "estate",
    staticClass: "btn-location",
    on: {
      "click": function click($event) {
        return _vm.showMap('estate');
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-map-location"
  }), _c('span', [_vm._v(_vm._s(_vm.formData.estateLL || "经纬度"))])])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "售楼部位置：",
      "prop": "officeAddress"
    }
  }, [_c('div', {
    staticClass: "location-box"
  }, [_c('el-input', {
    attrs: {
      "placeholder": "售楼部地址"
    },
    model: {
      value: _vm.formData.officeAddress,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "officeAddress", $$v);
      },
      expression: "formData.officeAddress"
    }
  }), _c('div', {
    ref: "office",
    staticClass: "btn-location",
    on: {
      "click": function click($event) {
        return _vm.showMap('office');
      }
    }
  }, [_c('i', {
    staticClass: "el-icon-map-location"
  }), _c('span', [_vm._v(_vm._s(_vm.formData.officeLL || "经纬度"))])])], 1)]), _vm.showMapPopover ? _c('el-popover', {
    ref: "pop",
    attrs: {
      "placement": "bottom-end",
      "width": "542",
      "trigger": "click",
      "reference": _vm.reference
    },
    on: {
      "hide": function hide($event) {
        _vm.showMapPopover = false;
      }
    }
  }, [_c('p', {
    staticClass: "f-form-tips mt10"
  }, [_vm._v("请在地图上准确标注当前项目的位置坐标")]), _c('div', {
    staticClass: "map-wrap mt10"
  }, [_c('a-map', {
    on: {
      "getLL": _vm.getLL
    }
  })], 1)]) : _vm._e(), _c('el-form-item', {
    attrs: {
      "label": "项目特点："
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('el-checkbox-group', {
    model: {
      value: _vm.formData.characteristicsSystemList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "characteristicsSystemList", $$v);
      },
      expression: "formData.characteristicsSystemList"
    }
  }, _vm._l(_vm.characteristicsSystemList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "label": item,
        "disabled": _vm.isDisableCharacteristic && !_vm.formData.characteristicsSystemList.includes(item)
      }
    });
  }), 1), _c('el-checkbox-group', {
    model: {
      value: _vm.formData.characteristicsList,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "characteristicsList", $$v);
      },
      expression: "formData.characteristicsList"
    }
  }, _vm._l(_vm.characteristicsList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      staticStyle: {
        "width": "120px"
      },
      attrs: {
        "label": item,
        "disabled": _vm.isDisableCharacteristic && !_vm.formData.characteristicsList.includes(item)
      }
    });
  }), 1), _c('el-input', {
    staticClass: "w180",
    attrs: {
      "maxlength": "5",
      "placeholder": "自定义",
      "size": "small",
      "disabled": _vm.isDisableCharacteristic
    },
    model: {
      value: _vm.diyTag,
      callback: function callback($$v) {
        _vm.diyTag = $$v;
      },
      expression: "diyTag"
    }
  }, [_c('el-button', {
    attrs: {
      "slot": "append",
      "type": "blue-text"
    },
    on: {
      "click": _vm.addTag
    },
    slot: "append"
  }, [_vm._v("确定")])], 1), _c('p', {
    staticClass: "f-form-tips mt10"
  }, [_vm._v(" 最多添加4个标签，自定义标签不超过5个汉字 ")])], 1)]), _c('el-form-item', {
    attrs: {
      "label": "状态：",
      "prop": "shelvesStatus",
      "rules": [_vm.rules.required()]
    }
  }, [_c('el-radio-group', {
    model: {
      value: _vm.formData.shelvesStatus,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "shelvesStatus", $$v);
      },
      expression: "formData.shelvesStatus"
    }
  }, [_c('el-radio-button', {
    attrs: {
      "label": 1
    }
  }, [_vm._v("上架")]), _c('el-radio-button', {
    attrs: {
      "label": 2
    }
  }, [_vm._v("下架")])], 1)], 1), _vm.formData.shelvesStatus == 1 ? _c('el-form-item', {
    staticClass: "choose-channel",
    attrs: {
      "label": "",
      "prop": "onLineChannel",
      "rules": [_vm.rules.required('至少选择一个')]
    }
  }, [_c('div', {
    staticClass: "f-form-block"
  }, [_c('div', {
    staticClass: "tip"
  }, [_vm._v("上架平台 "), _c('span', [_vm._v("（至少上架1个平台）")])]), _c('el-checkbox-group', {
    model: {
      value: _vm.formData.onLineChannel,
      callback: function callback($$v) {
        _vm.$set(_vm.formData, "onLineChannel", $$v);
      },
      expression: "formData.onLineChannel"
    }
  }, _vm._l(_vm.channelList, function (item, index) {
    return _c('el-checkbox', {
      key: index,
      class: {
        'special-disabled': _vm.distributionFlag && _vm.platformId == item.channelId
      },
      staticStyle: {
        "width": "130px"
      },
      attrs: {
        "label": item.id,
        "disabled": item.disabled || _vm.distributionFlag && _vm.platformId == item.channelId,
        "checked": _vm.distributionFlag && _vm.platformId == item.channelId
      }
    }, [_vm._v(_vm._s(item.letTitle))]);
  }), 1)], 1)]) : _vm._e()], 1), _c('div', {
    attrs: {
      "slot": "footer"
    },
    slot: "footer"
  }, [_c('el-button', {
    on: {
      "click": _vm.cancel
    }
  }, [_vm._v("取 消")]), _c('el-button', {
    attrs: {
      "type": "primary"
    },
    on: {
      "click": _vm.submit
    }
  }, [_vm._v("确 定")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }