var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('el-cascader', {
    ref: "areaCascader",
    attrs: {
      "props": _vm.props,
      "options": _vm.cityList,
      "clearable": "",
      "placeholder": "城市-区域-商圈"
    },
    on: {
      "visible-change": _vm.cascaderVisibleChange,
      "expand-change": _vm.cascaderExpandChange,
      "change": _vm.areaSelect
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }