import { getEnterpriseChannelStatus } from "@/api/distribution";
import { getSystemCharacteris } from "@/api/estate";
import { getSiteList, getAreaListBySite } from "@/api/common";
export default {
  data() {
    return {
      cityList: [], //城市站点
      areaList: [], //商圈区域
      channelList: [], // 渠道列表
      showAreaCascader: true // 防止cascader组件切换数据报错
    };
  },
  computed: {
    isDisableCharacteristic() {
      //选择四个特点后，禁止再选择特点
      return (
        this.formData.characteristicsList.length +
          this.formData.characteristicsSystemList.length >=
        4
      );
    }
  },
  watch: {
    "formData.onLineChannel": {
      immediate: true,
      handler(arr) {
        let checkList = this.channelList.filter(item => arr.includes(item.id));
        // 是否包含P+
        let hasPp = checkList.some(item => item.flag === 2);
        // 是否包含分销
        let hasFx = checkList.some(item => item.flag === 1);

        const handleDisabled = flag => item => {
          // 根据平台flag，设置item的disabled属性
          return {
            ...item,
            disabled: item.flag === flag // 1 P+业务系统，2 分销平台，3 购房平台
          };
        };
        this.channelList = this.channelList.map(
          // 选择项中有p+，禁用分销：找到flag == 1的项，设置disabled = true
          // 选择项中有分销平台，禁用p+: 找到flag ==2的项，对应项的disabled = true
          handleDisabled(hasPp ? 1 : hasFx ? 2 : false)
        );
      }
    }
  },
  async created() {
    this.getSiteList();
    this.getSystemCharacteris();
  },
  methods: {
    async getSiteList() {
      //获取城市站点列表
      const res = await getSiteList({ type: 1 });
      if (res) {
        this.cityList = res;
      }
    },
    async cityChange(companyCode) {
      //根据城市获取区域商圈
      this.showAreaCascader = false;
      const res = await getAreaListBySite({ companyCode });
      if (res && res instanceof Array) {
        this.areaList = res;
      } else {
        this.areaList = [];
      }
      this.showAreaCascader = true;
    },
    async getSystemCharacteris() {
      //获取楼盘特色列表
      const res = await getSystemCharacteris({
        propertyTypes: this.formData.propertyTypesList,
        showLoading: false
      });
      if (res) {
        this.characteristicsSystemList = res;
      }
    },
    async getEnterpriseChannelStatus() {
      // 获取渠道信息
      const res = await getEnterpriseChannelStatus({
        channelSource: 2,
        removeDefaultEstateId: this.$route.path === "/workbench/estate"
      });
      if (res) {
        this.channelList = res.channelVos.map(item => {
          return {
            ...item,
            flag: item.channelSource === 2 ? 2 : item.isPcn ? 1 : 3, // 添加flag字段，区分平台 1 分销平台，2 P+业务系统，3 其它平台
            disabled: false
          };
        });
      }
    },
    addTag() {
      //添加楼盘特色自定义标签
      if (
        this.formData.characteristicsList.length +
          this.formData.characteristicsSystemList.length ==
        4
      ) {
        return;
      }
      let tag = this.diyTag.trim();
      if (tag) {
        if (
          this.formData.characteristicsSystemList.includes(tag) ||
          this.characteristicsList.includes(tag)
        ) {
          this.$showError("已有该特点");
          return;
        }
        this.characteristicsList.push(tag);
        this.formData.characteristicsList.push(tag);
      }
      this.diyTag = "";
    }
  }
};
