var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "amap"
  }, [_c('div', {
    staticClass: "main",
    attrs: {
      "id": "amap"
    }
  }, [_c('el-amap-search-box', {
    staticClass: "search-box",
    attrs: {
      "search-option": _vm.searchOption,
      "on-search-result": _vm.onSearchResult
    }
  }), _c('el-amap', {
    ref: "amap",
    attrs: {
      "vid": "amap",
      "zoom": 12,
      "center": _vm.marker.position,
      "events": _vm.events
    }
  }, [_c('el-amap-marker', {
    attrs: {
      "position": _vm.marker.position,
      "visible": _vm.marker.visible
    }
  })], 1)], 1), _c('div', {
    staticClass: "result"
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }